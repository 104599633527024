$backgroundColor: #c2ffee!important;
$textColor: #505050!important;
$linkActiveColor: $textColor;
$bannerColor: #8befd4!important;
$bannerBorderColor: transparent!important;
$bannerTextColor: black!important;
$filterColColor: #fbf6da!important;
$sizeBubbleBgColor: white;





.App {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
}

.AppContent {
  flex: 1 0 auto;
}

.sizeCircle {
  background-color: $sizeBubbleBgColor;
  display: inline-block;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  border: 1px solid grey;
  position: relative;
  transition: background-color 0.2s ease;
  user-select: none;
}

.sizeCircle.active {
  background-color: #eaeaea;
  border-width: 2px;
}

.sizeCircle .size {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.sizeCircle .trash {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(20%) translateY(-20%);
}

.category {
  transition: background-color 0.2s ease;
  user-select: none;
}

.category.categoryActive {
  background: #eaeaea;
}

.SortableImageContainer {
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
}

.SortableImage {
  position: relative;
  display: inline-block;
}

.SortableImage img {
  height: 200px;
}

.SortableImage .trash {
  position: absolute;
  right: 0px;
  top: 0px;
  background: white;
  border: 1px solid black;
}

.App .bg-dark {
  background-color: $backgroundColor;
  color: $textColor;
}

.App .bg-dark a {
  color: $textColor;
}

.App .bg-dark a.nav-link.active {
  color: $linkActiveColor;
}

.App .bg-dark a.nav-link:after {
  border-color: $textColor;
}

.App .bg-dark .copy {
  border-color: $textColor;
}

.App .bg-dark .navbar-toggler {
  color: $textColor;
}

.App .appBanner {
  background-color: $bannerColor;
  border-color: $bannerBorderColor;
  color: $bannerTextColor;
  padding-top: 0px;
  padding-bottom: 0px;
}

.App .filterCol {
  background-color: $filterColColor;
}
