.order-item-image-container {
  width: 100%; 
  text-align: center;
  overflow: hidden;
}

.order-item-image-container img {
  position: relative;
  left: 100%;
  margin-left: -200%;
  height: 100px;
  width: auto !important;
}


table.products {
  border-collapse: collapse;
  border-spacing: 0;
}
table.products td {
  text-align: center;
}
table.products th {
  padding-left: 1rem;
  padding-right: 1rem;
}
table.products tr:nth-child(even):not(:last-child) {
  background: #CCC;
}