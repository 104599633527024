html, 
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding-top: 56px;
}
